/* Checkbox */

.checkbox {
    margin: 8px 0;
    width: 100%;
}

.checkbox label {
    cursor: pointer;
    user-select: none;
    display: block;
    position: relative;
    padding-left: 30px;
}

input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.checkbox__icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    background-image: url("../../../img/checkbox-normal.svg");
    background-position: center center;
    background-repeat: no-repeat;
}

input[type="checkbox"]:hover ~ .checkbox__icon {
    background-image: url("../../../img/checkbox-hover.svg");
}

input[type="checkbox"]:checked ~ .checkbox__icon {
    background-image: url("../../../img/checkbox-checked.svg");
}

input[type="checkbox"]:checked:hover ~ .checkbox__icon {
    background-image: url("../../../img/checkbox-checked-hover.svg");
}

input[type="checkbox"]:disabled ~ .checkbox__icon {
    background-image: url("../../../img/checkbox-disabled.svg");
}

input[type="checkbox"]:checked:disabled ~ .checkbox__icon {
    background-image: url("../../../img/checkbox-checked-disabled.svg");
}

.checkbox--disabled {
    opacity: 0.5;
}
