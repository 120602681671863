/* ErrorMessage */

.error-message {
    padding: 10px 10px 10px 40px;
    /* background: url("../../../img/error.svg") left 10px center no-repeat rgba(255, 76, 119, 0.2); */
    border: 1px solid #ff4c77;
    border-radius: 8px;
    font: 400 12px/20px "Montserrat";
    color: #ff4c77;
}
