/* Managetype */

/* .popup__wrap {
    background-color: #fff;
} */

.popup__close {
    background: url("../../img//close-gray.svg") center center no-repeat;
    border: 2px solid #cee1f1;
}

.manage-type__title {
    font: 700 30px/44px "Montserrat";
    color: #1a2d45;
    margin-bottom: 32px;
}

.manage-type_input .input__title {
    font: 700 11px/16px "Montserrat";
    color: rgba(26, 45, 69, 0.7);
}

.manage-type_input .input__wrap {
    border: 1px solid #e6e8ec !important;
}

.manage-type_input .input__input {
    color: #000;
}

.manage-type__input .input__wrap {
    border: 1px solid #e6e8ec;
}

.input--focused .input__wrap {
    border-color: #8e3659 !important;
}

.manage-type_inputContainer {
    margin-bottom: 32px;
}

.manage-type_input .input__title {
    color: rgba(26, 45, 69, 0.7);
    font: 700 11px/16px "Montserrat";
}

.manage-type__select-title {
    margin-bottom: 12px;
    font: 700 12px/12px "Montserrat";
    color: rgb(26, 45, 69, 0.7);
    text-transform: uppercase;
}

.manage-type__select {
    width: 100%;
    max-height: 150px;
    border: 2px solid #e6e8ec;
    border-radius: 12px;
    outline: none;
    overflow-y: hidden;
}
.manage-type__select--error {
    border-color: #ff4c77;
}
.manage-type__select {
    margin-bottom: 32px;
}

.manage-type__option {
    padding: 8px 16px 8px;
    font: 600 14px/22px "Montserrat";
    color: #000000;
}

.input-title {
    margin-bottom: 32px;
}
.manage-type__option:checked {
    background: #8e3659;
    color: #fff;
}

.input-tasks {
    margin-bottom: 10px;
}

.manage-type__container {
    margin-bottom: 32px;
}

.manage-type-container {
    margin-bottom: 32px;
}

.manage-type__subtitle {
    font: 700 11px/16px "Montserrat";
    color: rgba(26, 45, 69, 0.7);
}

.manage-type_checkboxContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
}

.manage-type__bottom-table-wrapperContainer {
    border: 1px solid #e6e8ec;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    padding: 18px 5px 18px 0px;
}

.manage-type__bottom-table-wrapper {
    max-height: 250px !important;
    overflow-y: scroll;
    scrollbar-color: #3b7dc1 #f2f5ff;
    scrollbar-width: thin;
}

.manage-type__bottom-table-wrapper::-webkit-scrollbar {
    width: 6px;
}
.manage-type__bottom-table-wrapper::-webkit-scrollbar-track {
    background: #cee1f1;
    border-radius: 5px;
}

.manage-type__bottom-table-wrapper::-webkit-scrollbar-thumb {
    background-color: #3b7dc1;
    border-radius: 5px;
}

.manage-type__bottom-table-wrapper {
    padding-right: 10px;
}

.manage-type__bottom-table-wrapper td {
    cursor: pointer;
    padding: 5px 20px;
}

.manage-type__table-selected {
    background-color: #8e3659;
    color: white;
}

.radio-btns_mainContainer {
    display: flex;
    flex-direction: column;
}

.manage-type__error {
    margin-bottom: 25px;
}

.error-container {
    position: relative;
}

.manage-type__errorMessage-duelTypes {
    position: absolute;
    left: 0px;
    color: red;
    font: 400 13px / 14px "Montserrat";
    bottom: 10px;
}

.manage-type__errorMessage-tasks {
    position: absolute;
    left: 0px;
    color: red;
    font: 400 13px / 14px "Montserrat";
    bottom: -20px;
}

/*
 * Responsive
 * https://getbootstrap.com/docs/5.1/layout/breakpoints/#max-width
*/
@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
    .manage-type__title {
        font: 700 26px/36px "Montserrat" !important;
    }
    .manage-type_checkboxContainer {
        flex-direction: column;
    }
}

@media (max-width: 575.98px) {
    .manage-type__title {
        font: 700 22px/30px "Montserrat" !important;
    }
}
