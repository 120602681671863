/* ManageRole */

/* .popup__wrap {
    background-color: #fff;
} */

.popup__close {
    background: url("../../../img/close-gray.svg") center center no-repeat;
    border: 2px solid #cee1f1;
}

.manage-role__title {
    font: 700 30px/44px "Montserrat";
    color: #1a2d45;
}

.manage-role__input .input__title {
    font: 700 11px/16px "Montserrat";
    color: rgba(26, 45, 69, 0.7);
}

.manage-role__input .input__input {
    color: #000;
}

.manage-role__input .input__wrap {
    border: 1px solid #e6e8ec;
}

.input--focused .input__wrap {
    border-color: #8e3659 !important;
}

.manage-role_inputContainer {
    margin-bottom: 32px;
}
/*
 * Responsive
 * https://getbootstrap.com/docs/5.1/layout/breakpoints/#max-width
*/
@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
    .manage-role-checkbox {
        font: 700 9px/14px "Montserrat";
    }
}

@media (max-width: 575.98px) {
}
