.manage-role-permissions {
    margin-bottom: 32px;
}

.manage-role_permission-container {
}

.manage-role_permission-container {
}

.manage-role-checkbox {
    margin-left: 5px;
    color: rgba(26, 45, 69, 0.7);
    font: 700 11px/16px "Montserrat";
}

.checkbox {
    width: 100% !important;
}

.manage-role_checkboxContainer {
    color: red;
}

.manage-role_sub-group {
    margin-left: 30px;
}

.group-checkboxes {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.manage-role__subtitle {
    font: 700 11px/16px "Montserrat";
    margin-bottom: 12px;
}

/*
 * Responsive
 * https://getbootstrap.com/docs/5.1/layout/breakpoints/#max-width
*/
@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
    .manage-role_sub-group {
        margin-left: 25px;
    }
}

@media (max-width: 575.98px) {
    .manage-role_sub-group {
        margin-left: 20px;
    }
    .group-checkboxes {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}
