/*Popup*/
.popup {
}

.popup__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8;
    background: #00000066;
}

.popup__wrap {
    width: 100%;
    height: auto;
    max-width: 700px;
    max-height: 90vh;
    padding: 32px;
    background: #3b7dc1;
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border-radius: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9;
    transform: translate(-50%, -50%);
    /* overflow-y: auto; */
}

.popup--overflow .popup__wrap {
    overflow: visible;
}

.popup--resize-drag .popup__wrap {
    resize: both;
    width: 600px;
    max-width: inherit;
    top: 0;
    left: 0;
    transform: none;
}

.popup--resize-drag .popup__close {
    top: 26px;
}

.popup__close {
    width: 40px;
    height: 40px;
    border: 2px solid #e6e8ec;
    border-radius: 40px;
    background: url("../../../img/close.svg") center center no-repeat;
    position: absolute;
    top: 36px;
    right: 36px;
    z-index: 9;
    cursor: pointer;
}

.popup__wrap--white {
    background-color: #fff;
    overflow-y: auto;
}

.popup__draggable {
    height: 22px;
    margin-bottom: 10px;
    border-top: 1px dotted #000;
    border-bottom: 1px dotted #000;
    position: relative;
    cursor: move;
}

.popup__draggable::after {
    content: "";
    border-bottom: 1px dotted #000;
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
}

/*
 * Responsive
 * https://getbootstrap.com/docs/5.1/layout/breakpoints/#max-width
*/
@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
    .popup__wrap {
        max-width: 440px;
        padding: 30px 25px;
    }

    .popup__close {
        width: 36px;
        height: 36px;
        top: 28px;
        right: 28px;
    }
}

@media (max-width: 575.98px) {
    .popup__wrap {
        width: 93%;
        padding: 25px 15px;
    }

    .popup__close {
        top: 25px;
        right: 15px;
    }
}
