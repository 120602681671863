.radio-btns_mainContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.radio-btns_btnContainer label {
    cursor: pointer;
}

/*
 * Responsive
 * https://getbootstrap.com/docs/5.1/layout/breakpoints/#max-width
*/
@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
    .radio-btns_mainContainer {
        flex-direction: column;
        gap: 5px;
    }
}
