.periodList-wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.periodList-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.periodList-title-text {
    font: 700 11px/16px "Montserrat";
    text-transform: uppercase;
    color: #1a2d45b2;
}

.periodList-title-add {
    font: 700 11px/16px "Montserrat";
    text-transform: uppercase;
    color: #3b7dc1;
    cursor: pointer;
}
