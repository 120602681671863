/* ManageRole */

/* .popup__wrap {
    background-color: #fff;
} */

.popup__close {
    background: url("../../img//close-gray.svg") center center no-repeat;
    border: 2px solid #cee1f1;
}

.delete-role_title {
    color: #1a2d45;
    font: 700 30px / 44px "Montserrat";
    margin: 50px 0px;
}

.delete-role__container {
    position: relative;
}

.delete-role-error {
    position: absolute;
    bottom: -25px;
    left: 0px;
    color: red;
}

/*
 * Responsive
 * https://getbootstrap.com/docs/5.1/layout/breakpoints/#max-width
*/
@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
    .delete-role_title {
        font: 700 24px/40px "Montserrat";
    }
}

@media (max-width: 575.98px) {
    .delete-role_title {
        font: 700 20px/36px "Montserrat";
    }
}
